<template>
  <v-expansion-panels focusable multiple v-model="show">
    <v-expansion-panel>
      <v-expansion-panel-header class="subheading font-weight-bold pa-1">
        <h3>{{ $t("studies.acv.texts.title") }}</h3>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row no-gutters>
          <v-col cols="12" class="text-right mt-2">
            <v-btn
              color="primary"
              :loading="isLoadingBtnSave"
              @click="saveTexts"
            >
              <v-icon small>fal fa-check-circle</v-icon>
              <span class="ml-1">
                {{ $t("studies.acv.texts.buttons.save") }}
              </span>
            </v-btn>
          </v-col>

          <!-- DescriptionACV -->
          <v-col cols="12" class="my-2">
            <ProductACVTextsTableDetails
              :title="$t('studies.acv.texts.description.title')"
              :item.sync="form.descriptionACV"
              @save="saveTexts"
            />
            <v-divider />
          </v-col>

          <!-- descriptionACVSpecific -->
          <v-col cols="12" class="my-2">
            <ProductACVTextsTableDetails
              :title="$t('studies.acv.texts.descriptionSpecific.title')"
              :item.sync="form.descriptionACVSpecific"
              @save="saveTexts"
            />
            <v-divider />
          </v-col>

          <!-- climateChangeEquivalence -->
          <v-col cols="12" class="my-2">
            <ProductACVTextsTableDetails
              :title="$t('studies.acv.texts.climateChangeEquivalence.title')"
              :item.sync="form.climateChangeEquivalence"
              @save="saveTexts"
            />
            <v-divider />
          </v-col>

          <!-- eutrophicationFreshWaterEquivalence -->
          <v-col cols="12" class="my-2">
            <ProductACVTextsTableDetails
              :title="
                $t(
                  'studies.acv.texts.eutrophicationFreshWaterEquivalence.title'
                )
              "
              :item.sync="form.eutrophicationFreshWaterEquivalence"
              @save="saveTexts"
            />
            <v-divider />
          </v-col>

          <!-- resourceUseFossilsEquivalence -->
          <v-col cols="12" class="my-2">
            <ProductACVTextsTableDetails
              :title="
                $t('studies.acv.texts.resourceUseFossilsEquivalence.title')
              "
              :item.sync="form.resourceUseFossilsEquivalence"
              @save="saveTexts"
            />
            <v-divider />
          </v-col>

          <!-- materialTextDescription -->
          <v-col cols="12" class="my-2">
            <ProductACVTextsTableDetails
              :title="$t('studies.acv.texts.materialTextDescription.title')"
              :item.sync="form.materialTextDescription"
              @save="saveTexts"
            />
            <v-divider />
          </v-col>

          <!-- recyclabilityTextDescription -->
          <v-col cols="12" class="my-2">
            <ProductACVTextsTableDetails
              :title="
                $t('studies.acv.texts.recyclabilityTextDescription.title')
              "
              :item.sync="form.recyclabilityTextDescription"
              @save="saveTexts"
            />
            <v-divider />
          </v-col>

          <!-- Product name -->
          <v-col cols="12" class="my-2">
            <ProductACVTextsTableDetails
              :title="$t('studies.acv.texts.publicProductNames.title')"
              :item.sync="form.publicProductNames"
              @save="saveTexts"
            />
            <v-divider />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "ProductACVTexts",

  components: {
    ProductACVTextsTableDetails: () =>
      import("@/components/Studies/ACV/ProductACVTextsTableDetails"),
  },

  props: {
    impact: { type: Object, default: () => {} },
    study: { type: Object, default: () => {} },
  },

  data() {
    return {
      show: [0],
      isLoadingBtnSave: false,
      form: {
        descriptionACV: this.impact.descriptionACV ?? {},
        descriptionACVSpecific: this.impact.descriptionACVSpecific ?? {},
        climateChangeEquivalence: this.impact.climateChangeEquivalence ?? {},
        eutrophicationFreshWaterEquivalence:
          this.impact.eutrophicationFreshWaterEquivalence ?? {},
        resourceUseFossilsEquivalence:
          this.impact.resourceUseFossilsEquivalence ?? {},
        materialTextDescription: this.impact.materialTextDescription ?? {},
        recyclabilityTextDescription:
          this.impact.recyclabilityTextDescription ?? {},
        publicProductNames: this.impact.publicProductNames ?? {},
      },
    };
  },

  methods: {
    saveTexts() {
      this.isLoadingBtnSave = true;

      this.$http
        .put(`/studies/${this.study.id}/acv/texts`, this.form, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("studies.acv.texts.edit.success")
          );
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtnSave = false;
        });
    },
  },
};
</script>

<style scoped></style>
